import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/use-home-equity-to-buy-home");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Use Home Equity to Buy a Home.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                What’s one of the most valuable financial assets a homeowner has? Home equity. A
                powerful tool when purchasing another home, whether a vacation property, upgrade or
                to build your real estate portfolio, home equity offers a range of benefits.
              </CommonParagraph>
              <CommonParagraph>
                Here’s how to effectively tap into your home equity and use it to buy another home.
              </CommonParagraph>
              <Heading type="h2">Accessing Your Equity</Heading>
              <CommonParagraph>
                Your equity grows as you pay down your mortgage, and your home’s value increases.
                That equity can be accessed in several ways, including:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  <strong>Home equity loan:</strong> This solution provides you with a lump sum
                  that’s based on your equity. You’ll get a fixed interest rate and repayment
                  schedule to help you pay back what you’ve borrowed.
                </li>
                <li>
                  <strong>Home equity line of credit (HELOC):</strong> Unlike a home equity loan
                  that provides a lump sum, a home equity line of credit allows you to borrow
                  against your equity as needed. That means there’s more flexibility in how much you
                  borrow and when. A HELOC typically comes with a variable interest rate.
                </li>
                <li>
                  <strong>Cash-out refinance:</strong> This involves refinancing your existing
                  mortgage for more than what you owe. Then, you take the difference in cash. While
                  a cash-out refinance can offer a lower interest rate, it’s important to note that
                  it may extend your mortgage term or increase your monthly payments.
                </li>
              </ul>
              <CommonParagraph>
                The best way to access your equity depends on your unique financial situation and
                goals.
              </CommonParagraph>
              <Heading type="h2">3 Benefits of Using Home Equity to Buy Another House</Heading>
              <CommonParagraph>
                Wondering how to use home equity? Many tap into theirs to{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego"
                  text="purchase another property"
                />
                , as it can offer several advantages that not only make the process smoother but
                potentially more cost-effective. Here are just three of the many reasons you should
                consider using your home equity to buy another house.
              </CommonParagraph>
              <Heading type="h3">1. Lower Down Payment</Heading>
              <CommonParagraph>
                One of the key benefits of using home equity to buy another house is the potential
                for a lower down payment. Tapping into your equity may make it easier to afford the
                home you’ve been dreaming of.
              </CommonParagraph>
              <Heading type="h3">2. Better Terms</Heading>
              <CommonParagraph>
                Because lenders often lower interest rates for borrowers with larger down payments,
                your equity may also help you secure more attractive loan terms. Better terms have
                the potential to save you thousands of dollars over the life of the loan.
              </CommonParagraph>
              <Heading type="h3">3. Streamlined Process</Heading>
              <CommonParagraph>
                If you have substantial equity, you may not need to rely on other forms of financing
                as much. That can simplify the homebuying process, making the transaction that much
                more efficient and straightforward.
              </CommonParagraph>
              <Heading type="h2">Using Your Equity to Buy a Second Home</Heading>
              <CommonParagraph>
                Considering buying a second home? Using your equity to do so can be a smart
                strategy, as it has the potential to make the purchase more affordable.
              </CommonParagraph>
              <CommonParagraph>
                For example, let’s say you have significant equity in your primary residence and are
                looking for a second property in a{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="San Diego community"
                />
                . You can actually use it as collateral to obtain a loan for your second home.
                However, it’s important to note that taking on a second mortgage can impact your
                long-term financial ambitions, so be sure to plan accordingly.
              </CommonParagraph>
              <Heading type="h2">Put Your Equity to Good Use Today</Heading>
              <CommonParagraph>
                Ready to use your equity to move or to purchase a second home? Turn to The Selby
                Team! We’ll help you find the perfect San Diego neighborhood that complements your
                lifestyle, so don’t hesitate to leverage your equity and{" "}
                <ContactSlideoutLink text="connect with us today" />.
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
